import React, {FC, useCallback, useEffect, useRef} from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Counter, CounterSize} from 'wix-ui-tpa/cssVars';
import cx from 'classnames';
import {classes as stylableClasses} from './ProductsQuantity.st.css';
import scssClasses from './ProductQuantity.scss';
import {UserInputType} from '../../../constants';
import {scrollAndFocusUserInput} from '../utils/scrollAndFocusUserInput';

export enum ProductQuantityDataHooks {
  container = 'product-quantity-container',
  counter = 'product-quantity-counter',
}

export interface ProductQuantityProps {
  value: number;
  onChange(value: number): void;
}

const ProductQuantityComponent: FC<ProductQuantityProps & ProvidedGlobalProps & IProvidedTranslationProps> = ({
  t,
  value,
  globals: {quantityRange, isProductSubmitted, userInputErrors, scrollAndFocusInputTarget, isQuickView, experiments},
  onChange,
}) => {
  const quantityContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    /* istanbul ignore next */
    if (!experiments.productPageOOIScrollToError) {
      return;
    }
    const isScrollAndFocusInputTarget = UserInputType.Quantity === scrollAndFocusInputTarget?.inputType;

    if (isScrollAndFocusInputTarget && quantityContainerRef.current) {
      scrollAndFocusUserInput(UserInputType.Quantity, quantityContainerRef.current, isQuickView);
    }
  }, [scrollAndFocusInputTarget, quantityContainerRef, isQuickView, experiments]);

  const handleInputChange = useCallback(
    (inputValue: string) => {
      const parsedValue = Number(inputValue);
      const validValue = isNaN(parsedValue) || inputValue === '' ? value : parsedValue;
      onChange(validValue);
    },
    [onChange, value]
  );

  /* istanbul ignore next: tested in sled */
  const isError = isProductSubmitted && userInputErrors[UserInputType.Quantity][0];
  /* istanbul ignore next: tested in sled */
  const getErrorMessage = useCallback(() => {
    if (value > quantityRange.max) {
      return t('QUANTITY_EXCEEDS_INVENTORY', {inventory: quantityRange.max});
    } else if (value < quantityRange.min) {
      return t('QUANTITY_BELOW_MINIMUM_RANGE', {minimum: quantityRange.min});
    }
    return null;
  }, [value, quantityRange.max, quantityRange.min, t]);

  const title = t('QUANTITY_LABEL');

  return (
    <div ref={quantityContainerRef} data-hook={ProductQuantityDataHooks.container}>
      <Counter
        size={CounterSize.medium}
        className={cx(stylableClasses.counterRoot, scssClasses.counterRoot)}
        data-hook={ProductQuantityDataHooks.counter}
        onChange={handleInputChange}
        value={value}
        max={quantityRange.max}
        min={quantityRange.min}
        step={1}
        label={title}
        inputAriaLabel={title}
        error={isError}
        errorMessage={getErrorMessage()}
        newErrorMessage
      />
    </div>
  );
};

export const ProductQuantity = withGlobalProps(withTranslations('globals.texts')(ProductQuantityComponent));
